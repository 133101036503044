<template>
  <div>
    <c-tab
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :height="tabHeight"
          @closePopup="closePopup"
          @changeStatus="changeStatus"
          @changeComplete="changeComplete"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'nearAccidentDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
        iimStatusNearCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      tab: 'nearRegisterTab', //처음 화면에 보여질 tab을 표시하는 부분
      addTabItems: [
        { name: 'nearCausePrevention', icon: 'construction', label: '원인 및 재발방지 관리', component: () => import(`${'./nearCausePrevention.vue'}`) },
      ],
      tabItems: [
        { name: 'nearRegisterTab', icon: 'edit', label: '아차사고 정보', component: () => import(`${'./nearRegisterTab.vue'}`) }
      ],
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 60);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      if (this.popupParam.iimNearAccidentId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
      // list setting  
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    changeStatus() {  //상태변화(등록했을 경우 개선관리 탭을 추가해서 보여줌)
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    changeComplete() {
      this.popupParam.iimStatusNearCd = 'ISNC000002';
    }
  }
};
</script>